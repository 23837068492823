import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { ContactUsPurchasing } from 'common/components/templates/ContactUsPurchasing'
import { getContactData } from 'common/utils/getContactData'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface ContactUsNewPlanPageProps {
    pageContext: any
}

export const ContactUsPurchasingPage: React.FC<ContactUsNewPlanPageProps> = ({
    pageContext
}) => {
    return <ContactUsPurchasing {...getNewPlanPageData(pageContext)} />
}

export default ContactUsPurchasingPage

export const getNewPlanPageData = (data: any) => ({
    layout: {
        ...getLayoutData(data),
        footer: {
            ...getLayoutData(data).footer,
            regLine: data?.footer__regulatory_text?.[0].text
        }
    },
    jumbotron: getJumbotronData(data),
    contact: getContactData(data.contact__form[0]),
    subsection: {
        title: data.contact_form_right_side__title,
        description: data.contact_form_right_side__description,
        featureList: data.contact_form_left_side__feature_list
    },
    healthTitle: data.contact_form_right_side__title,
    healthSubtitle: data.contact_form_right_side__subtitle,
    healthDescription: data.contact_form_right_side__description,
    healthPhone: data.contact_form_right_side__first_label,
    healthEmail: data.contact_form_right_side__second_label,
    healthHours: data.contact_form_right_side__note_description,
    otherQueries: {
        title: data.title,
        text: data.text,
        heading: data.heading
    },
    ctaLabel: data.card_cta_0396ba7__cta_label,
    ctaUrl: data.card_cta_0396ba7__cta_url,
    variant: data.card_cta_0396ba7__cta_variant
})
