import { Box, Grid, makeStyles } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { LeftIntro } from 'common/components/organisms/LeftIntro'
import React from 'react'
import { CardCtaContained } from '../molecules/CardCta'
import { Spacer } from '../molecules/Spacer'
import { Text } from '../molecules/Text'
import { Contact, ContactProps } from '../organisms/Contact'
import { Layout, LayoutProps } from '../organisms/Layout'

export interface ContactUsNewPlanProps {
    jumbotron: JumbotronProps
    layout: LayoutProps
    contact: ContactProps
    subsection: any
    ctaLabel: string
    ctaUrl?: string
    variant?: string
    otherQueries: any
    healthPhone: string
    healthTitle: string
    healthSubtitle: string
    healthDescription: string
    healthEmail: string
    healthHours: string
}

export const ContactUsPurchasing: React.FC<ContactUsNewPlanProps> = ({
    jumbotron,
    layout,
    contact,
    subsection,
    otherQueries,
    ctaUrl,
    ctaLabel,
    variant,
    healthTitle,
    healthSubtitle,
    healthDescription,
    healthPhone,
    healthEmail,
    healthHours
}) => {
    const useStyles = makeStyles({
        padding: { padding: '0px 60px' },
        title: { color: '#3A3160', fontSize: '40px' },
        subtitle: {
            color: '#4E4670',
            fontSize: '12px',
            textTransform: 'uppercase'
        },
        description: {
            color: '#766F90',
            fontSize: '20px'
        },
        phone: { color: '#3A3160', fontSize: '24px' },
        hours: { color: '#3A3160', fontSize: '16px' },
        email: { color: '#3A3160', fontSize: '24px' }
    })

    const classes = useStyles()
    return (
        <>
            <Layout {...layout}>
                <Jumbotron {...jumbotron} />

                <Box>
                    <Wrapper component='section'>
                        <Spacer />
                        <Contact {...contact} />
                        <Spacer />
                    </Wrapper>
                </Box>

                <Wrapper style={{ background: '#fff' }} component='section'>
                    <Spacer variant='md' />
                    <Grid item xs={12} md={6} className={classes.padding}>
                        <Text
                            label={healthTitle}
                            className={classes.title}
                            html
                        />
                        <Text
                            label={healthSubtitle}
                            className={classes.subtitle}
                            html
                        />
                        <Text
                            label={healthDescription}
                            className={classes.description}
                            html
                        />

                        <a
                            href={'tel:' + healthPhone}
                            className={classes.phone}
                        >
                            {healthPhone}
                        </a>
                        <Text
                            label={healthHours}
                            className={classes.hours}
                            html
                        />
                        <a
                            href={'mailto:' + healthEmail}
                            className={classes.email}
                        >
                            {healthEmail}
                        </a>
                    </Grid>
                    <Spacer />
                </Wrapper>
                <Box bgcolor='#F7F8FB' component='section'>
                    <Spacer variant='md' />
                    <Wrapper>
                        <LeftIntro
                            text={otherQueries.text}
                            title={otherQueries.title}
                            heading={otherQueries.heading}
                        />

                        <CardCtaContained
                            label={ctaLabel}
                            url={ctaUrl}
                            variant='secondary'
                        />
                    </Wrapper>
                </Box>
            </Layout>
        </>
    )
}
